import React from 'react';

const PhotoBackground = () => (
  <>
    <div className="photoBackground" />
    {/* <div className="photoBackgroundOverlay"></div> */}
  </>
);

export default PhotoBackground;
